// @flow

import { Col, Row } from 'antd';

import AntdContainer from '../components/AntdContainer';
import HtmlHead from "../components/HtmlHead";
import Link from '../components/Link';
import PrerenderAsyncComp from '../components/PrerenderAsyncComp';
import React from "react"
import { StaticImage } from "gatsby-plugin-image";
import lazyLoadable from '../lib/lazyLoadable';

// NOTE: /* #__LOADABLE__ */ is required for my own wrappers! Cannot move it to lazyLoadable
const ContactUs = lazyLoadable(/* #__LOADABLE__ */ () => import(/* webpackChunkName: "contactUsComp" */ '../components/index/ContactUs'));
const SecurityView = lazyLoadable(/* #__LOADABLE__ */ () => import(/* webpackChunkName: "securityViewComp" */ '../components/index/SecurityView'));
const WhyView = lazyLoadable(/* #__LOADABLE__ */ () => import(/* webpackChunkName: "whyViewComp" */ '../components/index/WhyView'));
const HowItWorksView = lazyLoadable(/* #__LOADABLE__ */ () => import(/* webpackChunkName: "howItWorksViewComp" */ '../components/index/HowItWorksView'));


const IndexPage = (props: Object): Function => {

  return (
    <>
      <HtmlHead title='Skip the Post Office - Send a Letter Online' description='Quick and Easy Way to Send Letters, Forms and Documents Online Anywhere in the World. No sign up required.' meta={{keywords: 'Send a Letter, Send Letters Online, Send a Letter Online, Send Mail Online, Quick Way to Send Letters, Snail Mail Online'}} extraStructData={{"@type": "Organization"}}/>
      <div className='landing'>
        <div className='section light'>
          <div className='content-wrapper'>
            <AntdContainer>
              <Row type="flex">

                  <Col xs={{offset:2, span: 20, order: 1}} md={{offset: 0, span: 12, order: 2}}>
                    <StaticImage layout='fluid' src='../images/progressive-landing-lg.jpg' maxWidth={782} alt='post seal carrying letter' />
                  </Col>
                  <Col xs={{span: 24, order: 2}} md={{span: 12, order: 1}} className='top-space-2x'>
                    <Col xs={24} sm={0} className='text-center'>
                      <h1 className='ant-typography'>Skip the post office - send a letter online.</h1>
                      <p>Quick and easy way to send letters, forms and documents online anywhere in the world. No sign up required.</p>
                    </Col>
                    <Col xs={0} sm={24}>
                      <h1 className='ant-typography'>Skip the post office - send a letter online.</h1>
                      <p>Quick and easy way to send letters, forms and documents online anywhere in the world. No sign up required.</p>
                    </Col>

                    <Col xs={0} sm={24} className='top-space-2x'>
                      <Link className='ant-btn wide-btn ant-btn-primary ant-btn-round ant-btn-lg' to='/send-letter'>Send a letter</Link>
                    </Col>
                    <Col xs={24} sm={0} className='top-space-2x'>
                      <Link className='ant-btn ant-btn-primary ant-btn-round ant-btn-lg ant-btn-block' to='/send-letter'>Send a letter</Link>
                    </Col>

                  </Col>
              </Row>
            </AntdContainer>
          </div>
        </div>
        <div className='section light'>
            <div className='content-wrapper'>
              <AntdContainer>
                <PrerenderAsyncComp name='howItWorksViewComp' comp={HowItWorksView} />
              </AntdContainer>
            </div>
        </div>
        <div className='section light'>
          <div className='content-wrapper'>
            <AntdContainer>
              <Row>
                <Col>
                  <PrerenderAsyncComp name='whyViewComp' comp={WhyView} />
                </Col>
              </Row>
            </AntdContainer>
          </div>
        </div>
        <div className='section light'>
          <div className='content-wrapper'>
            <AntdContainer>
              <Row>
                <Col>
                  <PrerenderAsyncComp name='securityViewComp' comp={SecurityView} />
                </Col>
              </Row>
            </AntdContainer>
          </div>
        </div>
        <div className='section gray'>
          <div className='content-wrapper'>
            <AntdContainer>
              <Row>
                <Col xs={24} md={{offset: 6, span: 12}}>
                  {
                    <PrerenderAsyncComp name='contactUsComp' comp={ContactUs} />
                    //<ContactUs />
                  }

                </Col>
              </Row>
            </AntdContainer>
          </div>
        </div>
      </div>
    </>
)};

export default IndexPage
