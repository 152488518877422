// @flow

import React, {useEffect, useState} from 'react';

import {isPrerender} from '../lib/util';

function _getRenderedComp(containerId: string) {

  if (!isPrerender) {
    const element = window.document.querySelector(
      `#${containerId}`
    )

    return element ? element.innerHTML : null;
  }

  return null;
}

// NOTE: this is coupled with lazyLoadable
const PrerenderAsyncComp = (props: Object) => {

  const Comp = props.comp;
  const id = 'prerendered__' + props.name;

  return (
    <section id={id}>
      <Comp
        fallback={
          <div dangerouslySetInnerHTML={{
              __html: _getRenderedComp(id)
            }}
          />
          }
        />
    </section>
  );
} 

export default PrerenderAsyncComp;
