// @flow

import {isPrerender} from '../lib/util';
import loadable from '@loadable/component';
import loadableVisibility from "react-loadable-visibility/loadable-components";

/***
  1) Prerender HTML during build time
  2) Rebuild HTML and download needed JS when the component is visible

  Unfortunately, dynamic paths aren't enabled (Looks like they should be possible, but not easy to set up)
***/
export default function lazyLoadable(importFunc: Function): Promise<any> {
  if (isPrerender) {
    const LoadableComp = loadable(importFunc);
    LoadableComp.preload();
    return LoadableComp;
  }

  return loadableVisibility(importFunc);
}
 
